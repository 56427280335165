// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/home/anima/web/edu.animaespacio.org/edu.anima/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-category-js": () => import("/home/anima/web/edu.animaespacio.org/edu.anima/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-tutorial-js": () => import("/home/anima/web/edu.animaespacio.org/edu.anima/src/templates/tutorial.js" /* webpackChunkName: "component---src-templates-tutorial-js" */),
  "component---src-templates-quiz-js": () => import("/home/anima/web/edu.animaespacio.org/edu.anima/src/templates/quiz.js" /* webpackChunkName: "component---src-templates-quiz-js" */),
  "component---src-templates-user-js": () => import("/home/anima/web/edu.animaespacio.org/edu.anima/src/templates/user.js" /* webpackChunkName: "component---src-templates-user-js" */),
  "component---src-pages-404-js": () => import("/home/anima/web/edu.animaespacio.org/edu.anima/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/anima/web/edu.animaespacio.org/edu.anima/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/home/anima/web/edu.animaespacio.org/edu.anima/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-js": () => import("/home/anima/web/edu.animaespacio.org/edu.anima/src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/anima/web/edu.animaespacio.org/edu.anima/.cache/data.json")

